const array = [
    { id: 1, nombre: 'AGUASCALIENTES'},
    { id: 2, nombre: 'BAJA CALIFORNIA'},
    { id: 3, nombre: 'BAJA CALIFORNIA SUR'},
    { id: 4, nombre: 'CAMPECHE'},
    { id: 5, nombre: 'CHIAPAS'},
    { id: 6, nombre: 'CHIHUAHUA'},
    { id: 7, nombre: 'COAHUILA'},
    { id: 8, nombre: 'COLIMA'},
    { id: 9, nombre: 'DURANGO'},
    { id: 10 ,nombre: 'ESTADO DE MÉXICO'},
    { id: 11 ,nombre: 'GUANAJUATO'},
    { id: 12 ,nombre: 'GUERRERO'},
    { id: 13 ,nombre: 'HIDALGO'},
    { id: 14 ,nombre: 'JALISCO'},
    { id: 15 ,nombre: 'MICHOACÁN'},
    { id: 16 ,nombre: 'MORELOS'},
    { id: 17 ,nombre: 'NAYARIT'},
    { id: 18 ,nombre: 'NUEVO LEÓN'},
    { id: 19 ,nombre: 'OAXACA'},
    { id: 20 ,nombre: 'PUEBLA'},
    { id: 21 ,nombre: 'QUERÉTARO'},
    { id: 22 ,nombre: 'QUINTANA ROO'},
    { id: 23 ,nombre: 'SAN LUIS POTOSÍ'},
    { id: 24 ,nombre: 'SINALOA'},
    { id: 25 ,nombre: 'SONORA'},
    { id: 26 ,nombre: 'TABASCO'},
    { id: 27 ,nombre: 'TAMAULIPAS'},
    { id: 28 ,nombre: 'TLAXCALA'},
    { id: 29 ,nombre: 'VERACRUZ'},
    { id: 30 ,nombre: 'YUCATÁN'},
    { id: 31 ,nombre: 'ZACATECAS'},
]

export default array