const array = [
  { id: 1, nombre: 'ACCESORIOS'},
  { id: 2, nombre: 'AUDIO'},
  { id: 3, nombre: 'COMPONENTES'},
  { id: 4, nombre: 'CONSUMIBLES'},
  { id: 5, nombre: 'ALMACENAMIENTO'},
  { id: 6, nombre: 'IMPRESION'},
  { id: 7, nombre: 'MONITORES'},
  { id: 8, nombre: 'CELULARES'},
  { id: 9, nombre: 'HERRAMIENTAS'}
]

export default array
